import "../src/jquery.datepicker.bootstrap.js";
import "../src/jquery.checkboxes-1.2.0.min.js";
import "../src/jquery.easy-autocomplete.js";
import "../src/jquery.snowfall.js";
import "../src/jquery.spin.js";
import "../src/best_in_place";
import "../src/best_in_place.datepicker.bootstrap";
import "../src/lightbox.js";
import "../src/bootstrap-input-spinner.js";

import {
  initializeTimepointRow,
  initializeTimepickers,
  updateTimepointDateFields,
  initializeTimepointBarcodeField,
  clearTimepointBarcodeField
} from "../src/common/common"

window.initializeTimepointRow = initializeTimepointRow;
window.initializeTimepointBarcodeField = initializeTimepointBarcodeField;
window.clearTimepointBarcodeField = clearTimepointBarcodeField;
window.initializeTimepickers = initializeTimepickers;

// Progress Bar

NProgress.configure({
  showSpinner: false,
  ease: "ease",
  speed: 500,
});

(function ($) {
  $.fn.inputFilter = function (inputFilter) {
    return this.on(
      "input keydown keyup mousedown mouseup select contextmenu drop",
      function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = "";
        }
      }
    );
  };
})(jQuery);

document.addEventListener("turbo:before-fetch-request", function() {
  NProgress.start();
});

document.addEventListener("turbo:frame-render", function() {
  NProgress.done();
  NProgress.remove();
});

document.addEventListener("turbo:before-stream-render", function() {
  NProgress.done();
  NProgress.remove();
});

$(document).on("turbo:render", function () {
  NProgress.done();
  NProgress.remove();
});

document.addEventListener('turbo:before-fetch-request', (event) => {
  event.detail.fetchOptions.headers['Turbo-Referrer'] = window.location.href
  event.detail.fetchOptions.headers['X-Turbo-Nonce'] = $("meta[name='csp-nonce']").prop('content')
});

document.addEventListener('turbo:before-cache', () => {
  [...document.querySelectorAll('script[nonce]')].forEach((script) => {
    script.setAttribute('nonce', script.nonce);
  });
});

document.addEventListener("turbo:load", function () {
  $("#version-icon").dblclick(function () {
    $('[data-toggle="tooltip"]').tooltip("dispose");
    $(this).html(
      '<i class="fas fa-alien" data-toggle="tooltip" title="GET SLOPED"></i> v1.24.1'
    );
    $('[data-toggle="tooltip"]').tooltip();
  });

  // ---------------------------------------------------------------------------
  // For bootstrap 4 layout

  $("#sidebar").mCustomScrollbar({
    theme: "minimal",
  });

  $("#sidebarCollapse").on("click", function () {
    $("#sidebar, #content").toggleClass("active");
    $(".collapse.in").toggleClass("in");
    $(".sidebar-collapse-toggle[aria-expanded=true]").attr("aria-expanded", "false");
  });

  // ---------------------------------------------------------------------------

  // Google Analytics log page view in turbo
  gtag("config", "", {
    page_path: window.location.pathname,
  });

  // ---------------------------------------------------------------------------

  // gogo datepickers
  $.extend($.fn.datepicker.defaults, {
    orientation: "auto auto",
    todayHighlight: true,
    format: "dd-M-yyyy",
    assumeNearbyYear: true,
  });

  $(".datepicker").datepicker();

  // gogo timepickers
  $(".timepicker").timepicker({
    showMeridian: false,
    showSeconds: true,
    minuteStep: 10,
    secondStep: 10, 
    icons: {
      up: "fas fa-chevron-up",
      down: "fas fa-chevron-down",
    },
  });

  // ---------------------------------------------------------------------------

  // in place editing
  $(".best_in_place").best_in_place();

  // ---------------------------------------------------------------------------

  // bootstrap tooptips
  $('[data-toggle="tooltip"]').tooltip({
        trigger : 'hover'
    });
  $('[data-toggle="popover"]').popover();
  $('[data-toggle="tooltip"]').on('click', function () {
    $(this).tooltip('hide')
  });

  // ---------------------------------------------------------------------------

  // enable range select
  $(".multi-select").checkboxes("range", true);

  // ---------------------------------------------------------------------------

  // Load items for clicked tab
  $('#item-table-tabs a[data-toggle="tab"]').on("show.bs.tab", function (e) {
    var resource_id = $("#items_info").data("resource-id");
    var state = $(e.target).data("state");
    var context = $("#items_info").data("context");
    var redirect_path = $("#items_info").data("redirect-path");
    var params = $("#items_info").data("params");
    var loaded = $(e.target).data("loaded");

    var data = {
      id: resource_id,
      state: state,
      context: context,
      redirect_path: `${redirect_path}?state=${state}`,
    };
    // Set correct page param name for the active tab
    var page_param = state + "_page";
    data[page_param] = params[page_param];

    // Pass inventory_type and workspace_id params if set in filter
    if (params["inventory_type"]) {
      data["inventory_type"] = params["inventory_type"];
    }
    if (params["workspace_id"]) {
      data["workspace_id"] = params["workspace_id"];
    }

    // Ready tab does not have a data-state value
    if (state && !loaded) {
      $("#" + state).spin({ top: "60%" });

      $.ajax({
        type: "GET",
        url: "/items/fetch",
        data: data,
        dataType: "script",
      }).done(function () {
        $("#" + state).spin(false);
        $(e.target).data("loaded", true);
      });
    }
  });

  // Push clicked tab into history as url hash (#expired)
  $('a[data-toggle="tab"]').on("click", function (e) {
    history.replaceState({ turbo: true }, null, $(this).attr("href"));
  });

  // Show tab found in url hash
  if (location.hash !== "") {
    $('a[href="' + location.hash + '"]').tab("show");
  }

  // ---------------------------------------------------------------------------

  // logic for items table
  $(".items-table-manage").hide();
  $(".items-table-transition").hide();
  $(".items-table-uncheck-all").hide();

  // Disabled links in items table should not be clickable
  $("body").on("click", ".disabled", function (e) {
    e.preventDefault();
  });

  // ---------------------------------------------------------------------------

  // handle checking rows in the items table

  var disable_links = function (links) {
    links.each(function () {
      $(this).removeAttr("href");
    });
  }
  var enable_links = function (links) {
    links.each(function () {
      $(this).attr("href", $(this).attr("data-href"));
    });
  }
  var handle_edit_multiples = function () {
      var checked = $(this)
        .parents("tbody")
        .find("tr")
        .has("input[type=checkbox]:checked");

        var actions_buttons = $(this)
          .parents("form")
          .find(".row-actions-button, .disable-when-checked")
        var links = $(this)
          .parents("form")
          .find("a.disable-when-checked")
        var actions_button_wrapper = $(this)
          .parents("form")
          .find(".actions-button-wrapper")

      if (checked.length > 0) {
        actions_buttons.attr("disabled", true);
        disable_links(links)
        actions_button_wrapper.attr("data-toggle", "tooltip");
        actions_button_wrapper.attr("data-placement", "left");
        actions_button_wrapper.attr("title", 'Click on the “Actions for checked items” button');
        actions_button_wrapper.tooltip();
        actions_button_wrapper.tooltip('enable');


        $("#page-entries-info")
          .hide();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".items-table-manage")
          .show();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".items-table-uncheck-all")
          .show();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".items-table-check-all")
          .hide();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".items-table-transition")
          .show();
        $(this)
          .parents("form")
          .find("#checked_item_count")
          .text(checked.length)
      } else {
        actions_buttons.attr("disabled", false);
        enable_links(links)
        actions_button_wrapper.tooltip('disable');
        $("#page-entries-info")
          .show();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".items-table-manage")
          .hide();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".items-table-uncheck-all")
          .hide();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".items-table-transition")
          .hide();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".items-table-check-all")
          .show();
      }

      // items table delete multiple handling
      var delete_link = $(this)
            .parents("form")
            .find(".delete_multiple_button")
      var delete_link_wrapper = $(this)
        .parents("form")
        .find(".delete_multiple_button_wrapper")
      if (checked.filter(element => { return !checked[element].getAttribute('class').includes("can-delete") }).length > 0){
        // if any selected items cannot be deleted
        if (!delete_link.hasClass("disabled")){
          delete_link.toggleClass("disabled", true);
          delete_link.attr("disabled", true);
          delete_link_wrapper.attr("data-toggle", "tooltip");
          delete_link_wrapper.attr("data-placement", "bottom");
          delete_link_wrapper.attr("title", "One or more selected items cannot be deleted.");
          delete_link_wrapper.tooltip();
          delete_link_wrapper.tooltip('enable');
        }
      } else {
        // if all selected items can be deleted
        if (delete_link.hasClass("disabled")){
          delete_link.toggleClass("disabled", false);
          delete_link.attr("disabled", false);
          delete_link_wrapper.tooltip('disable');
        }
      }
    }


  $(".tab-pane, .handle_edit_multiples").on(
    "change",
    '.multi-select input[type="checkbox"]', handle_edit_multiples
  );
  $(".nav-item").on(
    "click", function (){
      $(".tab-pane")
          .find(".actions-button-wrapper")
          .tooltip('hide')
    });

  // ---------------------------------------------------------------------------

  // highlight rows that are checked
  $(".tab-pane").on("change", ":checkbox", function () {
    $(this).closest("tr").toggleClass("active", this.checked);
  });

  // ---------------------------------------------------------------------------

  // handle edit and delete multiple items
  $(".tab-pane").on("click", '[id="edit_multiple"]', function (e) {
    $(".tab-pane.active #edit_multiple_form").submit();
  });

  $(".tab-pane").on("click", '[id="delete_multiple"]', function (e) {
    var confirmation = confirm("Are you sure?");
    if (confirmation) {
      e.preventDefault();
      var input = $("<input>")
        .attr("type", "hidden")
        .attr("name", "item_action")
        .val("delete");
      $(".tab-pane.active #edit_multiple_form").append($(input));
      $(".tab-pane.active #edit_multiple_form").submit();
    } else {
      e.preventDefault();
    }
  });

  // ---------------------------------------------------------------------------

  // handle transitioning multiple items
  function transitionMultipleItems(
    state,
    e,
    that,
    parent_form,
    canTransitionReserved
  ) {
    if ($(that).hasClass("disabled")) {
      e.preventDefault();
    } else {
      e.preventDefault();
      var state_input = $("<input>")
        .attr("type", "hidden")
        .attr("name", "transition_action")
        .val(state);
      var can_transition_reserved_input = $("<input>")
        .attr("type", "hidden")
        .attr("name", "can_transition_reserved")
        .val(canTransitionReserved);
      parent_form.append($(state_input));
      parent_form.append($(can_transition_reserved_input));
      parent_form.submit();
    }
  }

  $(".tab-pane").on("click", '.transition_multiple_items', function (e) {
    var action = $(this).attr('data-action')
    transitionMultipleItems(action, e, this, $(this).parents("form"), false);
  });

  $(".tab-pane").on("click", '[id="subject_visit_use_multiple"]', function (e) {
    $(this)
      .parents("form")
      .find("input#item_ids_:checked")
      .parents("tr")
      .find("td[id$='_state']")
      .html('<i class="fas fa-spinner fa-spin"></i> Updating');
    transitionMultipleItems("use", e, this, $(this).parents("form"), true);
  });

  $(".tab-pane").on("click", '[id="subject_visit_reset_multiple"]', function (
    e
  ) {
    $(this)
      .parents("form")
      .find("input#item_ids_:checked")
      .parents("tr")
      .find("td[id$='_state']")
      .html('<i class="fas fa-spinner fa-spin"></i> Updating');
    transitionMultipleItems("reset", e, this, $(this).parents("form"), true);
  });

  $("#edit_multiple_form").on(
    "click",
    '[id="add_to_shipment_multiple"]',
    function (e) {
      var input = $("<input>")
        .attr("type", "hidden")
        .attr("name", "shipment_action")
        .val("add_multiple");
      $("#edit_multiple_form").append($(input));
      $("#edit_multiple_form").submit();
    }
  );


  // ---------------------------------------------------------------------------

  // invitations interactions
  if ($("#invitation_role_select").val() == "site_admin") {
    $("#invitation_workspaces_list").hide();
    $("#invitation_staff_accesss_list").hide();
  } else if ($("#invitation_role_select").val() == "site_staff") {
    $("#invitation_workspaces_list").show();
    $("#invitation_staff_accesss_list").show();
  }

  $("#invitation_role_select").change(function () {
    const selected = $(this).val();
    if (selected == "site_admin") {
      $("#invitation_workspaces_list").hide();
      $("#invitation_staff_accesss_list").hide();
    } else if (selected == "site_staff") {
      $("#invitation_staff_accesss_list").show();
      $("#invitation_workspaces_list").show();
    }
  });

  // org invites
  if ($("#invitation_role_select").val() == "organization_admin") {
    $("#invitation_protocols_list").hide();
  } else if ($("#invitation_role_select").val() == "organization_staff") {
    $("#invitation_protocols_list").show();
  }

  $("#invitation_role_select").change(function () {
    const selected = $(this).val();
    if (selected == "organization_admin") {
      $("#invitation_protocols_list").hide();
    } else if (selected == "organization_staff") {
      $("#invitation_protocols_list").show();
    }
  });

  // sponsor invites
  if ($("#invitation_role_select").val() == "sponsor_admin") {
    $("#invitation_protocols_list").hide();
    $("#invitation_labs_list").hide();
  } else if ($("#invitation_role_select").val() == "sponsor_staff") {
    $("#invitation_protocols_list").show();
    $("#invitation_labs_list").show();
  }

  $("#invitation_role_select").change(function () {
    const selected = $(this).val();
    if (selected == "sponsor_admin") {
      $("#invitation_protocols_list").hide();
      $("#invitation_labs_list").hide();
    } else if (selected == "sponsor_staff") {
      $("#invitation_protocols_list").show();
      $("#invitation_labs_list").show();
    }
  });

  // supplier invites
  if ($("#invitation_role_select").val() == "supplier_admin") {
    $("#invitation_protocols_list").hide();
    $("#invitation_depots_list").hide();
    $("#invitation_labs_list").hide();
  } else if ($("#invitation_role_select").val() == "supplier_staff") {
    $("#invitation_protocols_list").show();
    $("#invitation_depots_list").show();
    $("#invitation_labs_list").show();
  }

  $("#invitation_role_select").change(function () {
    const selected = $(this).val();
    if (selected == "supplier_admin") {
      $("#invitation_protocols_list").hide();
      $("#invitation_depots_list").hide();
      $("#invitation_labs_list").hide();
    } else if (selected == "supplier_staff") {
      $("#invitation_protocols_list").show();
      $("#invitation_depots_list").show();
      $("#invitation_labs_list").show();
    }
  });

  // ---------------------------------------------------------------------------

  // ahoy tracking js events
  $("#help_center_link").click(function (e) {
    ahoy.track("Help center link clicked", e.target.dataset);
  });

  $("#referral_link").click(function (e) {
    ahoy.track("Referral link copied", e.target.dataset);
  });

  $("#referral_share_email").click(function (e) {
    ahoy.track("Share referral button clicked", { channel: "email" });
  });

  $("#referral_share_facebook").click(function (e) {
    e.preventDefault(); // necessary because this was reloading the page
    ahoy.track("Share referral button clicked", { channel: "facebook" });
  });

  $("#referral_share_linkedin").click(function (e) {
    e.preventDefault(); // necessary because this was reloading the page
    ahoy.track("Share referral button clicked", { channel: "linkedin" });
  });

  // ---------------------------------------------------------------------------
  // BOOTSTRAP INPUT SPINNER - CREATES + AND - BUTTONS TO THE RIGHT OF NUMBER INPUTS
  $("input[type='number']").not('.no-input-spinner').not('stimulus-spinner').inputSpinner();

  // disable increasing and decreasing the number input value on scroll
  $('input[type=number]').on('mousewheel', function(e) {
    $(e.target).blur();
  });
  
  // ---------------------------------------------------------------------------

  // hide/show add parts fields in new inventory form
  $("#inventory_inventory_type").on('change', function(e){
    if ($(this).val() == "Drug") {
      $("#add-parts-fields").show();
      $("#inventory-temperature-requirements").show();
    } else {
      $("#add-parts-fields").hide();
      $("#inventory-temperature-requirements").hide();
    }
  });

  // hide/show add parts fields in new inventory template form
  $("#inventory_template_inventory_type").on('change', function(e){
    if ($(this).val() == "Drug") {
      $("#add-parts-fields").show();
    } else {
      $("#add-parts-fields").hide();
    }
  });

  $("#has-parts-box").on('change', function(e){
    if ($(this).prop('checked')) {
      $("#parts-details-fields").show();
      $("#part-name-field").attr('disabled', false);
      $("#part-name-field").attr('required', true);
      $("#part-quantity-field").attr('disabled', false);
      $("#part-quantity-field").attr('required', true);
    } else {
      $("#parts-details-fields").hide();
      $("#part-name-field").attr('disabled', true);
      $("#part-name-field").attr('required', false);
      $("#part-quantity-field").attr('disabled', true);
      $("#part-quantity-field").attr('required', false);
    }
  });

  $("#has-no-parts-box").on('change', function(e){
    if ($(this).prop('checked')) {
      $("#parts-details-fields").hide();
      $("#part-name-field").attr('disabled', true);
      $("#part-name-field").attr('required', false);
      $("#part-quantity-field").attr('disabled', true);
      $("#part-quantity-field").attr('required', false);
    } else {
      $("#parts-details-fields").show();
      $("#part-name-field").attr('disabled', false);
      $("#part-name-field").attr('required', true);
      $("#part-quantity-field").attr('disabled', false);
      $("#part-quantity-field").attr('required', true);
    }
  });

  // ---------------------------------------------------------------------------

  function setProtocolClinicalTrialAttributeFieldsVisibility(workspace_type) {
    if (workspace_type == "Clinical Trial") {
      $("#protocol-clinical-trial-attribute-fields").show();
      $("#protocol-clinical-trial-attribute-fields #protocol_name").prop(
        "disabled",
        false
      );

      $("#protocol-supplies-attribute-fields").hide();
      $("#protocol-supplies-attribute-fields #protocol_name").prop(
        "disabled",
        true
      );
    } else {
      $("#protocol-supplies-attribute-fields").show();
      $("#protocol-supplies-attribute-fields #protocol_name").prop(
        "disabled",
        false
      );

      $("#protocol-clinical-trial-attribute-fields").hide();
      $("#protocol-clinical-trial-attribute-fields #protocol_name").prop(
        "disabled",
        true
      );
    }
  }

  // Called when form is loaded to make sure workspace clinical trial attributes are shown for render 'new' after failed create
  setProtocolClinicalTrialAttributeFieldsVisibility(
    $("#protocol_workspace_type").val()
  );

  $("#protocol_workspace_type").change(function () {
    setProtocolClinicalTrialAttributeFieldsVisibility($(this).val());
  });

  // ---------------------------------------------------------------------------

 

  $(".wrapper").on("click", "#close_workspace_modal", function (event) {
    event.preventDefault();
    var workspace_id = event.target.dataset.workspaceId;
    var close_link = event.target.dataset.closeLink

    $.ajax({
      type: "GET",
      url: "/workspaces/confirm_close_workspace_modal?id=" + workspace_id,
      success: function(data){
        $(".workspace-close-modal-space").replaceWith(data);
        $(".workspace-close-modal-space").find("form").attr("action", close_link);
        $(".workspace-close-modal-space").modal("show");
      } 
    })

  })

  $(".wrapper").on("click", "#cannot_close_site_modal", function (event) {
    event.preventDefault();
    var site_id = event.target.dataset.siteId;
 
  

    $.ajax({
      type: "GET",
      url: "/sites/" + site_id + "/cannot_close_site_modal",
      success: function(data){
    
        $(".cannot-close-site-modal").replaceWith(data);

        $(".cannot-close-site-modal").modal("show");
      } 
    })

  })

  // Inject resource info into close modal
  $("#close_modal").on("show.bs.modal", function (event) {
    var link = $(event.relatedTarget); // link that triggered the modal
    var name = link.data("name"); // Extract info from data-* attributes
    var title = link.data("title");
    var close_link = link.data("close-link");
    var button_text = link.data("button-text");
    var form_method = link.data("form-method");
    var modal_body_content = link.data("modal-body-content");
    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    var modal = $(this);
    modal.find(".modal_body_content").html(modal_body_content);
    modal.find(".modal-title").text(title);
    modal.find("form").attr("action", close_link);
    modal.find(".btn.btn-danger").val(button_text);
    modal.find(".btn.btn-danger").attr("data-disable-with", button_text);

    // Append hidden method value which Rails uses to simulate a delete request
    if (form_method == "delete") {
      modal
        .find("form")
        .append('<input type="hidden" name="_method" value="delete">');
    }

    // Only allow modal close button to be clicked when resource name is entered
    $('#close_modal input[type="text"]').keyup(function () {
      var input = $(this).val();
      if (input == name) {
        modal.find(".btn.btn-danger").removeProp("disabled");
      } else {
        modal.find(".btn.btn-danger").prop("disabled", "disabled");
      }
    });
  });

  // Clear resource_name input when modal is closed
  $("#close_modal").on("hidden.bs.modal", function (event) {
    $('#close_modal input[type="text"]').val("");
  });

  // ---------------------------------------------------------------------------

  /* Initialize quick nav select */
  $(".selectpicker").each(function (i, el) {
    if (!$(el).parent().hasClass("bootstrap-select")) {
      $(el).selectpicker("refresh");
    }
  });

  // ---------------------------------------------------------------------------

  // Enable tooltips for dynamically created dropdown in items table Move checked button
  $("body").tooltip({
    selector: ".items-table-transition-link",
  });

  // ---------------------------------------------------------------------------

  // Show used state name fields when checked
  $("#rename-used-state-checkbox").on("change", ":checkbox", function () {
    $("#rename-used-state-fields").toggle();
  });

  // ---------------------------------------------------------------------------

  // // logic for stocks table
  $(".stocks-table-manage").hide();
  $(".stocks-table-transition").hide();
  $(".stocks-table-uncheck-all").hide();

  // ---------------------------------------------------------------------------

  // handle checking rows in the stocks table
  $("#edit_multiple_stock_form").on(
    "change",
    '.multi-select input[type="checkbox"]',
    function () {
      var checked = $(this)
        .parents("tbody")
        .find("tr")
        .has("input[type=checkbox]:checked");

      if (checked.length > 1) {
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".stocks-table-manage")
          .show();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".stocks-table-uncheck-all")
          .show();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".stocks-table-transition")
          .show();
      } else {
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".stocks-table-manage")
          .hide();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".stocks-table-uncheck-all")
          .hide();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".stocks-table-transition")
          .hide();
      }
    }
  );

  // ---------------------------------------------------------------------------

  // highlight rows that are checked
  $("#edit_multiple_stock_form").on("change", ":checkbox", function () {
    $(this).closest("tr").toggleClass("active", this.checked);
  });

  // ---------------------------------------------------------------------------

  // handle edit and delete multiple items
  $("#edit_multiple_stock_form").on("click", '[id="edit_multiple"]', function (
    e
  ) {
    $("#edit_multiple_stock_form").submit();
  });

  $("#edit_multiple_stock_form").on(
    "click",
    '[id="delete_multiple"]',
    function (e) {
      var confirmation = confirm("Are you sure?");
      if (confirmation) {
        var input = $("<input>")
          .attr("type", "hidden")
          .attr("name", "stock_action")
          .val("delete");
        $("#edit_multiple_stock_form").append($(input));
        $("#edit_multiple_stock_form").submit();
      } else {
        e.preventDefault();
      }
    }
  );

  $("#edit_multiple_stock_form").on(
    "click",
    '[id="transfer_multiple"]',
    function (e) {
        var input = $("<input>")
          .attr("type", "hidden")
          .attr("name", "stock_action")
          .val("transfer");
        $("#edit_multiple_stock_form").append($(input));
        $("#edit_multiple_stock_form").submit();
    }
  );

  // ---------------------------------------------------------------------------

  // handle transitioning multiple stocks
  function transitionMultipleStocks(state, e, that) {
    if ($(that).hasClass("disabled")) {
      e.preventDefault();
    } else {
      var input = $("<input>")
        .attr("type", "hidden")
        .attr("name", "transition_action")
        .val(state);
      $("#edit_multiple_stock_form").append($(input));
      $("#edit_multiple_stock_form").submit();
    }
  }

  $("#edit_multiple_stock_form").on(
    "click",
    '[id="expire_multiple"]',
    function (e) {
      transitionMultipleStocks("expire", e, this);
    }
  );

  $("#edit_multiple_stock_form").on(
    "click",
    '[id="damage_multiple"]',
    function (e) {
      transitionMultipleStocks("damage", e, this);
    }
  );

  $("#edit_multiple_stock_form").on("click", '[id="lost_multiple"]', function (
    e
  ) {
    transitionMultipleStocks("lost", e, this);
  });

  $("#edit_multiple_stock_form").on(
    "click",
    '[id="return_multiple"]',
    function (e) {
      transitionMultipleStocks("return", e, this);
    }
  );

  $("#edit_multiple_stock_form").on(
    "click",
    '[id="dispose_multiple"]',
    function (e) {
      transitionMultipleStocks("dispose", e, this);
    }
  );

  $("#edit_multiple_stock_form").on("click", '[id="reset_multiple"]', function (
    e
  ) {
    transitionMultipleStocks("reset", e, this);
  });

  $("#edit_multiple_stock_form").on(
    "click",
    '[id="add_to_shipment_multiple"]',
    function (e) {
      var input = $("<input>")
        .attr("type", "hidden")
        .attr("name", "shipment_action")
        .val("add_multiple");
      $("#edit_multiple_stock_form").append($(input));
      $("#edit_multiple_stock_form").submit();
    }
  );

  // ---------------------------------------------------------------------------

  // Load destination sites for selected protocol
  $("#shipment_sponsor_form_protocol_id").on("change", function (e) {
    // Disable select and add spinner in label
    $("#shipment_sponsor_form_destination_id").prop("disabled", true);
    $("#shipment_sponsor_form_destination_id_label").html(
      'Site <i class="fas fa-spinner fa-spin"></i>'
    );
    $("#shipment_sponsor_form_inventory_template_id").prop("disabled", true);
    $("#shipment_sponsor_form_inventory_template_id_label").html(
      'Inventory template <i class="fas fa-spinner fa-spin"></i>'
    );

    var data = {
      protocol_id: $(this).val(),
      stock_id: $("#stock_ids_").val(),
    };

    $.ajax({
      type: "GET",
      url: "/shipments/fetch_destinations",
      data: data,
      dataType: "script",
    }).done(function () {
      // Enable select and remove spinner
      $("#shipment_sponsor_form_destination_id").prop("disabled", false);
      $("#shipment_sponsor_form_destination_id_label").html("Site");
      $("#shipment_sponsor_form_inventory_template_id").prop("disabled", false);
      $("#shipment_sponsor_form_inventory_template_id_label").html(
        "Inventory template"
      );
    });
  });

  // ---------------------------------------------------------------------------

  // // logic for manifests table
  $(".manifests-table-manage").hide();
  $(".manifests-table-uncheck-all").hide();

  // ---------------------------------------------------------------------------

  // handle checking rows in the manifests table
  $("#edit_multiple_manifests_form").on(
    "change",
    '.multi-select input[type="checkbox"]',
    function () {
      var checked = $(this)
        .parents("tbody")
        .find("tr")
        .has("input[type=checkbox]:checked");

      if (checked.length > 1) {
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".manifests-table-manage")
          .show();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".manifests-table-uncheck-all")
          .show();
      } else {
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".manifests-table-manage")
          .hide();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".manifests-table-uncheck-all")
          .hide();
      }
    }
  );

  // ---------------------------------------------------------------------------

  // highlight rows that are checked
  $("#edit_multiple_manifests_form").on("change", ":checkbox", function () {
    $(this).closest("tr").toggleClass("active", this.checked);
  });

  // ---------------------------------------------------------------------------

  // handle delete multiple items
  $("#edit_multiple_manifests_form").on(
    "click",
    '[id="delete_multiple"]',
    function (e) {
      var confirmation = confirm("Are you sure?");
      if (confirmation) {
        var input = $("<input>")
          .attr("type", "hidden")
          .attr("name", "manifest_action")
          .val("delete");
        $("#edit_multiple_manifests_form").append($(input));
        $("#edit_multiple_manifests_form").submit();
      } else {
        e.preventDefault();
      }
    }
  );

  $("#edit_multiple_manifests_form").on(
    "click",
    '[id="receive_multiple"]',
    function (e) {
      var input = $("<input>")
        .attr("type", "hidden")
        .attr("name", "manifest_action")
        .val("receive");
      $("#edit_multiple_manifests_form").append($(input));
      $("#edit_multiple_manifests_form").submit();
    }
  );

  $("#edit_multiple_manifests_form").on(
    "click",
    '[id="report_problem_multiple"]',
    function (e) {
      var input = $("<input>")
        .attr("type", "hidden")
        .attr("name", "manifest_action")
        .val("report_problem");
      $("#edit_multiple_manifests_form").append($(input));
      $("#edit_multiple_manifests_form").submit();
    }
  );

  $("#edit_multiple_manifests_form").on(
    "click",
    '[id="reset_multiple"]',
    function (e) {
      var input = $("<input>")
        .attr("type", "hidden")
        .attr("name", "manifest_action")
        .val("reset");
      $("#edit_multiple_manifests_form").append($(input));
      $("#edit_multiple_manifests_form").submit();
    }
  );

  // ---------------------------------------------------------------------------

  // Load available stock quantity for selected inventory template
  $("#manifest_form_inventory_template_id").on("change", function (e) {
    // Add spinner while new count loads
    $("#manifest_form_available_quantity").html(
      '<i class="fas fa-spinner fa-spin"></i>'
    );

    var depot_id = $("#depot_id").data("depot-id");

    var data = {
      inventory_template_id: $(this).val(),
      depot_id: depot_id,
    };

    $.ajax({
      type: "GET",
      url: "/manifests/fetch_available_quantity",
      data: data,
      dataType: "script",
    });
  });

  // Load available stock quantity for selected supplier SKU
  $("#manifest_form_sku_id").on("change", function (e) {
    // Add spinner while new count loads
    $("#manifest_form_available_quantity").html(
      '<i class="fas fa-spinner fa-spin"></i>'
    );

    var depot_id = $("#depot_id").data("depot-id");

    var data = {
      sku_id: $(this).val(),
      depot_id: depot_id,
    };

    $.ajax({
      type: "GET",
      url: "/manifests/fetch_available_quantity_from_supplier",
      data: data,
      dataType: "script",
    });
  });

  // Load available stock quantity for selected depot
  $("#shipment_form_depot_id").on("change", function (e) {
    // Add spinner while new count loads
    $("#shipment_form_available_quantity").html(
      '<i class="fas fa-spinner fa-spin"></i>'
    );

    var inventory_template_id = $("#inventory_template_id").data(
      "inventory-template-id"
    );
    var needed_quantity = $("#needed_quantity").data("needed-quantity");

    var data = {
      depot_id: $(this).val(),
      inventory_template_id: inventory_template_id,
      needed_quantity: needed_quantity,
    };

    $.ajax({
      type: "GET",
      url: "/shipments/fetch_available_quantity",
      data: data,
      dataType: "script",
    });
  });

  // Load available stock quantity for selected depot for supplier order
  $("#supplier_shipment_form_depot_id").on("change", function (e) {
    // Add spinner while new count loads
    $("#supplier_shipment_form_available_quantity").html(
      '<i class="fas fa-spinner fa-spin"></i>'
    );

    var sku_id = $("#sku_id").data("sku-id");
    var needed_quantity = $("#needed_quantity").data("needed-quantity");

    var data = {
      depot_id: $(this).val(),
      sku_id: sku_id,
      needed_quantity: needed_quantity,
    };

    $.ajax({
      type: "GET",
      url: "/shipments/fetch_available_quantity_for_order",
      data: data,
      dataType: "script",
    });
  });

  // ---------------------------------------------------------------------------

  // Don't show email field when self_admin checked
  $("#self-admin-checkbox").on("change", ":checkbox", function () {
    $("#site-admin-email").toggle();
  });

  $(".best_in_place_order_quantity").bind("ajax:error", function (
    evt,
    data,
    status,
    xhr
  ) {
    $(this).click();
    $(this).effect("shake", { distance: 3 });
  });

  // ---------------------------------------------------------------------------

  if ($("body.shipments-shipment_events").length) {
    var mymap = L.map("shipment_events_map").setView([51.505, -0.09], 13);

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
      tileSize: 512,
      maxZoom: 18,
      zoomOffset: -1,
      id: 'mapbox/streets-v11',
      accessToken: "pk.eyJ1Ijoic2xvcGVpbyIsImEiOiJjbGR1Y3hsNm8wNGp6M3ZvNzhoNnBjNDVuIn0.zB2KtJfyYIxNdUEjtDI71A",
    }).addTo(mymap);

    var shipment_events = $("#shipment-events").data("shipment-events");
    var origin = $("#origin").data("origin");
    var origin_address = $("#origin-address").data("origin-address");
    var destination = $("#destination").data("destination");
    var destination_address = $("#destination-address").data(
      "destination-address"
    );
    var shipment = $("#shipment").data("shipment");

    var bounds = L.latLngBounds();

    var markers = [];

    for (let i = 0; i < shipment_events.length; i++) {
      var event = shipment_events[i];

      if (event.lat && event.lon) {
        var existing_marker = null;

        var point = L.latLng(event.lat, event.lon);

        for (let j = 0; j < markers.length; j++) {
          var marker = markers[j];

          if (point.equals(marker.getLatLng())) {
            existing_marker = marker;
            break;
          }
        }

        if (!existing_marker) {
          var event_icon = L.ExtraMarkers.icon({
            icon: "fa-number",
            number: (markers.length + 1).toString(),
            markerColor: "blue",
            shape: "square",
            prefix: "fa",
            extraClasses: "number-marker",
          });
          var marker = L.marker(point, { icon: event_icon }).addTo(mymap);
          markers.push(marker);
          bounds.extend(point);
          marker.bindPopup(event.status_details);
          event.marker_index = markers.indexOf(marker);
        } else {
          var popup = existing_marker.getPopup();
          var popup_content = popup.getContent();
          popup.setContent(popup_content + "<br>" + event.status_details);
          event.marker_index = markers.indexOf(existing_marker);
        }
      }
    }

    for (let i = 0; i < markers.length - 1; i++) {
      const latlngs = [markers[i].getLatLng(), markers[i + 1].getLatLng()];
      L.polyline(latlngs, { color: "blue" }).addTo(mymap);
    }

    mymap.fitBounds(bounds);

    var last_marker = markers[markers.length - 1];
    last_marker.openPopup();

    var origin_icon = L.ExtraMarkers.icon({
      icon: "fa-warehouse",
      markerColor: "green",
      shape: "square",
      prefix: "fa",
    });
    var origin_marker = L.marker([origin_address.lat, origin_address.lon], {
      icon: origin_icon,
    }).addTo(mymap);
    origin_marker.bindPopup(
      "<p>" +
        origin.name +
        "</p>" +
        "<p>" +
        origin_address.address_line_1 +
        "<br>" +
        origin_address.city +
        ", " +
        origin_address.state_province_region +
        " " +
        origin_address.zip_postal_code +
        "<br>" +
        origin_address.country +
        "</p>"
    );
    L.polyline([origin_marker.getLatLng(), markers[0].getLatLng()], {
      color: "blue",
      dashArray: "10",
    }).addTo(mymap);

    var destination_icon = L.ExtraMarkers.icon({
      icon: "fa-hospital",
      markerColor: "red",
      shape: "square",
      prefix: "fa",
    });
    var destination_marker = L.marker(
      [destination_address.lat, destination_address.lon],
      { icon: destination_icon }
    ).addTo(mymap);
    destination_marker.bindPopup(
      "<p>" +
        destination.name +
        "</p>" +
        "<p>" +
        destination_address.address_line_1 +
        "<br>" +
        destination_address.city +
        ", " +
        destination_address.state_province_region +
        " " +
        destination_address.zip_postal_code +
        "<br>" +
        destination_address.country +
        "</p>"
    );

    const last_shipment_event = shipment_events[shipment_events.length - 1];
    if (last_shipment_event.status == "DELIVERED") {
      L.polyline([last_marker.getLatLng(), destination_marker.getLatLng()], {
        color: "blue",
        dashArray: "10",
      }).addTo(mymap);
    }

    $(".shipment-event-row").click(function () {
      var selected_event_id = $(this).data("id");

      var event = shipment_events.find(function (e) {
        return e.id == selected_event_id;
      });

      selected_marker = markers[event.marker_index];
      mymap.flyTo(selected_marker.getLatLng(), 13, {
        animate: true,
        duration: 1,
      });
      selected_marker.openPopup();
    });

    $(".origin-address").click(function () {
      mymap.flyTo(origin_marker.getLatLng(), 13, {
        animate: true,
        duration: 1,
      });
      origin_marker.openPopup();
    });

    $(".destination-address").click(function () {
      mymap.flyTo(destination_marker.getLatLng(), 13, {
        animate: true,
        duration: 1,
      });
      destination_marker.openPopup();
    });
  }

  // ---------------------------------------------------------------------------

  $(".pharmacy-address-checkbox").on("change", ":checkbox", function () {
    $(".pharmacy-address-fields").toggle();
  });

  // ---------------------------------------------------------------------------

  if ($("#sortable").length > 0) {
    return $("#sortable").sortable({
      axis: "y",
      items: ".item",
      cancel: ".item-unsortable",
      cursor: "move",
      sort: function (e, ui) {
        return ui.item.addClass("active-item-shadow");
      },
      stop: function (e, ui) {
        ui.item.removeClass("active-item-shadow");
        return ui.item.children("td").effect("highlight", {}, 1000);
      },
      update: function (e, ui) {
        var subject_visit_template_id = ui.item.data(
          "subject-visit-template-id"
        );
        var position = ui.item.index();
        return $.ajax({
          type: "POST",
          url: "/subject_visit_templates/update_row_order",
          dataType: "json",
          data: {
            subject_visit_template: {
              subject_visit_template_id: subject_visit_template_id,
              row_order_position: position,
            },
          },
        });
      },
    });
  }

  // ---------------------------------------------------------------------------
  // for barcode schema templates reorderable table

  if ($("#barcode-schema-templates-sortable").length > 0) {
    return $("#barcode-schema-templates-sortable").sortable({
      axis: "y",
      items: ".item",
      cursor: "move",
      sort: function (e, ui) {
        return ui.item.addClass("active-item-shadow");
      },
      stop: function (e, ui) {
        ui.item.removeClass("active-item-shadow");
        return ui.item.children("td").effect("highlight", {}, 1000);
      },
      update: function (e, ui) {
        var barcode_schema_template = ui.item.data(
          "barcode-schema-template-id"
        );
        var position = ui.item.index();
        return $.ajax({
          type: "POST",
          url: "/barcode_schema_templates/update_row_order",
          dataType: "json",
          data: {
            barcode_schema_template: {
              barcode_schema_template_id: barcode_schema_template,
              row_order_position: position,
            },
          },
        });
      },
    });
  }

  $("#barcode_schema_data_source").on("change", function () {
    if ($("#barcode_schema_data_source").val() == "Static Value") {
      $("#barcode_static_value_field").show();
    } else {
      $("#barcode_static_value_field").hide();
    }
  });

  // ---------------------------------------------------------------------------
  // for requisition schema templates reorderable table

  if ($("#requisition-schema-templates-sortable").length > 0) {
    return $("#requisition-schema-templates-sortable").sortable({
      axis: "y",
      items: ".item",
      cursor: "move",
      sort: function (e, ui) {
        return ui.item.addClass("active-item-shadow");
      },
      stop: function (e, ui) {
        ui.item.removeClass("active-item-shadow");
        return ui.item.children("td").effect("highlight", {}, 1000);
      },
      update: function (e, ui) {
        var requisition_schema_template = ui.item.data(
          "requisition-schema-template-id"
        );
        var position = ui.item.index();
        return $.ajax({
          type: "POST",
          url: "/requisition_schema_templates/update_row_order",
          dataType: "json",
          data: {
            requisition_schema_template: {
              requisition_schema_template_id: requisition_schema_template,
              row_order_position: position,
            },
          },
        });
      },
    });
  }

  $("#requisition_schema_data_source").on("change", function () {
    if ($("#requisition_schema_data_source").val() == "Static Value") {
      $("#requisition_static_value_field").show();
    } else {
      $("#requisition_static_value_field").hide();
    }
  });

  // ---------------------------------------------------------------------------

  // Show offset fields when schedule start checked
  $("#schedule-start-checkbox").on("change", ":checkbox", function () {
    $("#offset-fields").toggle();
  });

  // ---------------------------------------------------------------------------

  // Show build visit fields when enroll subject checked
  $("#enroll-subject-checkbox").on("change", ":checkbox", function () {
    $("#enroll-subject-fields").toggle();
  });

  // ---------------------------------------------------------------------------

  // Load schedule start and unscheduled visit templates for subject visit schedule
  $("#subject_visit_schedule_id").on("change", function (e) {
    // Add spinner while new visits load
    $("#subject-visit-fields").html('<i class="fas fa-spinner fa-spin"></i>');

    var data = {
      subject_visit_schedule_id: $(this).val(),
    };

    $.ajax({
      type: "GET",
      url: "/subjects/fetch_subject_visit_templates",
      data: data,
      dataType: "script",
    });
  });

  // ---------------------------------------------------------------------------

  $(".tab-pane").on("click", ".subject-visit-use-link", function () {
    $(this)
      .parents(".dropdown")
      .find("button.dropdown-toggle")
      .dropdown("toggle");
    $(this)
      .parents("tr")
      .find("td[id$='_state']")
      .html('<i class="fas fa-spinner fa-spin"></i> Updating');
  });

  // ---------------------------------------------------------------------------

  function setShortname(visit_type) {
    // If no shortname exists, put a default one based on visit type
    if ($("#subject_visit_template_shortname").val() == "") {
      if (visit_type == "Screening") {
        var text = "SCRN";
      } else if (visit_type == "Randomization") {
        var text = "RND";
      } else if (visit_type == "Baseline") {
        var text = "BSLN";
      } else if (visit_type == "Visit") {
        var text = "V";
      } else if (visit_type == "Follow up") {
        var text = "FUP";
      } else if (visit_type == "End of Treatment") {
        var text = "EOT";
      }

      $("#subject_visit_template_shortname").val(text);
    }
  }

  setShortname($("#subject_visit_template_visit_type").val());

  $("#subject_visit_template_visit_type").change(function () {
    selected = $(this).val();

    if (selected == "Screening") {
      var text = "SCRN";
    } else if (selected == "Randomization") {
      var text = "RND";
    } else if (selected == "Baseline") {
      var text = "BSLN";
    } else if (selected == "Visit") {
      var text = "V";
    } else if (selected == "Follow up") {
      var text = "FUP";
    } else if (selected == "End of Treatment") {
      var text = "EOT";
    }

    $("#subject_visit_template_shortname").val(text);
  });

  // ---------------------------------------------------------------------------

  // logic for orders table
  $(".orders-table-transition").hide();
  $(".orders-table-uncheck-all").hide();

  // ---------------------------------------------------------------------------

  // handle checking rows in the orders table
  $("#edit_multiple_order_form").on(
    "change",
    '.multi-select input[type="checkbox"]',
    function () {
      var checked = $(this)
        .parents("tbody")
        .find("tr")
        .has("input[type=checkbox]:checked");

      if (checked.length > 1) {
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".orders-table-uncheck-all")
          .show();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".orders-table-transition")
          .show();
      } else {
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".orders-table-uncheck-all")
          .hide();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".orders-table-transition")
          .hide();
      }
    }
  );

  // ---------------------------------------------------------------------------

  // highlight rows that are checked
  $("#edit_multiple_order_form").on("change", ":checkbox", function () {
    $(this).closest("tr").toggleClass("active", this.checked);
  });

  // ---------------------------------------------------------------------------

  // handle edit and delete multiple orders
  $("#edit_multiple_order_form").on("click", '[id="edit_multiple"]', function (
    e
  ) {
    $("#edit_multiple_order_form").submit();
  });

  // ---------------------------------------------------------------------------

  // handle transitioning multiple orders
  function transitionMultipleOrders(state, e, that) {
    if ($(that).hasClass("disabled")) {
      e.preventDefault();
    } else {
      var input = $("<input>")
        .attr("type", "hidden")
        .attr("name", "transition_action")
        .val(state);
      $("#edit_multiple_order_form").append($(input));
      $("#edit_multiple_order_form").submit();
    }
  }

  $("#edit_multiple_order_form").on(
    "click",
    '[id="approve_multiple"]',
    function (e) {
      transitionMultipleOrders("approve", e, this);
    }
  );

  $("#edit_multiple_order_form").on(
    "click",
    '[id="cancel_multiple"]',
    function (e) {
      transitionMultipleOrders("cancel", e, this);
    }
  );

  $("#edit_multiple_order_form").on(
    "click",
    '[id="acknowledge_multiple"]',
    function (e) {
      transitionMultipleOrders("acknowledge", e, this);
    }
  );

  $("#edit_multiple_order_form").on(
    "click",
    '[id="start_processing_multiple"]',
    function (e) {
      transitionMultipleOrders("start_processing_multiple", e, this);
    }
  );

  $("#edit_multiple_order_form").on(
    "click",
    '[id="sponsor_reset_multiple"]',
    function (e) {
      transitionMultipleOrders("sponsor_reset", e, this);
    }
  );

  $("#edit_multiple_order_form").on(
    "click",
    '[id="supplier_reset_multiple"]',
    function (e) {
      transitionMultipleOrders("supplier_reset", e, this);
    }
  );

  // Load visit templates for selected visit schedule
  $("#link_to_schedule_subject_visit_schedule_id").on("change", function (e) {
    // Add spinner in visit template fields
    $("#visit_template_fields").html('<i class="fas fa-spinner fa-spin"></i>');

    var inventory_id = $("#inventory_id").val();

    var data = {
      subject_visit_schedule_id: $(this).val(),
    };

    $.ajax({
      type: "GET",
      url: "/inventories/" + inventory_id + "/fetch_subject_visit_templates",
      data: data,
      dataType: "script",
    });
  });

  // Toggle quantity field disabled when checkbox checked
  $("#visit_template_fields").on("change", ":checkbox", function () {
    var checked = $(this).prop("checked");
    var quantity_field = $(this)
      .parents("#visit_template_row")
      .find(".inventory_demand_template_quantity");

    if (checked) {
      quantity_field.attr("disabled", false);
      quantity_field.val(0);
    } else {
      quantity_field.attr("disabled", true);
      quantity_field.val("");
    }
  });

  // ---------------------------------------------------------------------------

  // logic for samples table
  $(".samples-table-transition").hide();
  $(".samples-table-uncheck-all").hide();

  // ---------------------------------------------------------------------------

  // handle checking rows in the samples table
  $("#edit_multiple_samples_form").on(
    "change",
    '.multi-select input[type="checkbox"]',
    function () {
      var checked = $(this)
        .parents("tbody")
        .find("tr")
        .has("input[type=checkbox]:checked");

      if (checked.length > 1) {
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".samples-table-uncheck-all")
          .show();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".samples-table-transition")
          .show();
      } else {
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".samples-table-uncheck-all")
          .hide();
        $(this)
          .parents("form")
          .find("#edit-multiple-controls")
          .children(".samples-table-transition")
          .hide();
      }
    }
  );

  // ---------------------------------------------------------------------------

  // highlight rows that are checked
  $("#edit_multiple_samples_form").on("change", ":checkbox", function () {
    $(this).closest("tr").toggleClass("active", this.checked);
  });

  // ---------------------------------------------------------------------------

  $("#edit_multiple_samples_form").on(
    "click",
    '[id="add_to_shipment_multiple"]',
    function (e) {
      var input = $("<input>")
        .attr("type", "hidden")
        .attr("name", "shipment_action")
        .val("add_multiple");
      $("#edit_multiple_samples_form").append($(input));
      $("#edit_multiple_samples_form").submit();
    }
  );


  // ---------------------------------------------------------------------------

  // force numeric entry
  $(".numeric").inputFilter(function (value) {
    return /^\d*$/.test(value);
  });

  // ---------------------------------------------------------------------------

  var options = {
    autoFocus: true,
    placeholder: "Scan or begin typing a barcode",
    adjustWidth: false,
    getValue: "value",
    url: function (phrase) {
      return "fetch_barcodes?v=" + phrase;
    },
    listLocation: "barcodes",
    list: {
      maxNumberOfElements: 20,
      match: {
        enabled: true,
      },
      onChooseEvent: function () {
        var data = {
          v: $("#sample_barcode_value").val(),
          sample_group_id: $("#sample_group_id").data("sample-group-id"),
        };
        $.ajax({
          type: "GET",
          url: "/fetch_barcode_status",
          data: data,
          dataType: "script",
        });
      },
      showAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
      hideAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
    },
    theme: "bootstrap",
    requestDelay: 500,
  };

  $("#sample_barcode_value").focus(function () {
    $(this).select();
  });
  $("#sample_barcode_value").easyAutocomplete(options);
  $("#sample_barcode_value").focus();

  // ---------------------------------------------------------------------------

  // catch enter and clear on register barcode field
  $("#sample_barcode_value").on("keypress", function (e) {
    if ($(this).val() == "") {
      $("#barcode_spinner").hide();
      $("#barcode_match").fadeOut("fast");
      $("#barcode_match_fields").fadeOut("fast");
      $("#barcode_no_match").fadeOut("fast");
      $("#barcode_extra").fadeOut("fast");
    }

    if (e.keyCode == 13) {
      e.preventDefault();
      $("#barcode_match").fadeOut("fast");
      $("#barcode_match_fields").fadeOut("fast");
      $("#barcode_no_match").fadeOut("fast");
      $("#barcode_extra").fadeOut("fast");
      $("#barcode_spinner").show();

      var data = {
        v: $("#sample_barcode_value").val(),
        sample_group_id: $("#sample_group_id").data("sample-group-id"),
      };
      $.ajax({
        type: "GET",
        url: "/fetch_barcode_status",
        data: data,
        dataType: "script",
      });
    }
  });

  // ---------------------------------------------------------------------------

  // Fixing markup for clear button
  $("#sample_barcode_value")
    .closest(".input-group")
    .each(function (i, inputGroup) {
      $(inputGroup).removeClass("input-group");
      var autocomplete = $(inputGroup).find(".easy-autocomplete");
      $(inputGroup).find(".input-group-append").appendTo(autocomplete);
      autocomplete.addClass("input-group");
    });

  $("#clear_sample_barcode_button").on("click", function () {
    $("#sample_barcode_value").val("");
    $("#sample_new_subject_identifier").val("");
    $("#barcode_match").fadeOut("fast");
    $("#barcode_match_fields").fadeOut("fast");
    $("#barcode_no_match").fadeOut("fast");
    $("#barcode_extra").fadeOut("fast");
    $("#sample_barcode_value").focus();
  });

  // ---------------------------------------------------------------------------

  // fix markup for input group
  $("#sample_new_subject_identifier")
    .closest(".input-group")
    .each(function (i, inputGroup) {
      $(inputGroup).removeClass("input-group");
      var autocomplete = $(inputGroup).find(".easy-autocomplete");
      $(inputGroup).find(".input-group-prepend").prependTo(autocomplete);
      autocomplete.addClass("input-group");
    });

  // force numeric entry
  $("#sample_new_subject_identifier.numeric").inputFilter(function (value) {
    return /^\d*$/.test(value);
  });

  // ---Sample Barcode Scan Cards Start--- //
  // ---------------------------------------------------------------------------

  // Check for valid barcode
  // $('#sample_shipment_barcode_value').on('keyup', function(e) {
  //   $("#barcode_scan_match").hide();
  //   $("#barcode_scan_no_match").hide();
  //
  //   var shipment_id = $('#shipment_id').data('shipment-id');
  //
  //   var data = {
  //     barcode_value: $(this).val(),
  //     shipment_id: shipment_id
  //   }
  //
  //   $.ajax({
  //     type: "GET",
  //     url: "/samples/add_to_shipment_by_barcode",
  //     data: data,
  //     dataType: "script"
  //   });
  // });

  // ---------------------------------------------------------------------------
  // site sample barcode scanner:
  var options = {
    autoFocus: true,
    placeholder: "Scan or begin typing a barcode",
    adjustWidth: false,
    getValue: "value",
    url: function (phrase) {
      shipment = $("#shipment_id").data("shipment-id");
      return shipment + "/fetch_site_sample_barcodes?v=" + phrase;
    },
    listLocation: "barcodes",
    list: {
      match: {
        enabled: true,
      },
      onChooseEvent: function () {
        var data = {
          v: $("#sample_shipment_barcode_value").val(),
          sample_group_id: $("#sample_group_id").data("sample-group-id"),
          shipment_id: $("#shipment_id").data("shipment-id"),
        };
        $.ajax({
          type: "GET",
          url: "/samples/add_to_shipment_by_barcode",
          data: data,
          dataType: "script",
        });
      },
      showAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
      hideAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
    },
    theme: "bootstrap",
    requestDelay: 500,
  };

  $("#sample_shipment_barcode_value").focus(function () {
    $(this).select();
  });
  $("#sample_shipment_barcode_value").easyAutocomplete(options);
  $("#sample_shipment_barcode_value").focus();

  // ---------------------------------------------------------------------------

  // Check for valid barcode, supplier shipment:
  // $("#supplier_sample_shipment_barcode_value").on("keyup", function (e) {
  //   $("#barcode_scan_match").hide();
  //   $("#barcode_scan_no_match").hide();
  //   var shipment_id = $('#shipment_id').data('shipment-id');
  //   var sample_group_id = $('#sample_group_id').data('sample-group-id');
  //   var data = {
  //     barcode_value: $(this).val(),
  //     shipment_id: shipment_id,
  //     sample_group_id: sample_group_id
  //   }
  //   console.log(data)
  //     $.ajax({
  //     type: "GET",
  //     url: "/samples/add_to_lab_shipment_by_barcode",
  //     data: data,
  //     dataType: "script"
  //   });
  // });

  // ---------------------------------------------------------------------------
  // lab/supplier sample barcode scanner:
  var options = {
    autoFocus: true,
    placeholder: "Scan or begin typing a barcode",
    adjustWidth: false,
    getValue: "value",
    url: function (phrase) {
      shipment = $("#shipment_id").data("shipment-id");
      return shipment + "/fetch_lab_sample_barcodes?v=" + phrase;
    },
    listLocation: "barcodes",
    list: {
      match: {
        enabled: true,
      },
      onChooseEvent: function () {
        var data = {
          v: $("#supplier_sample_shipment_barcode_value").val(),
          sample_group_id: $("#sample_group_id").data("sample-group-id"),
          shipment_id: $("#shipment_id").data("shipment-id"),
        };
        $.ajax({
          type: "GET",
          url: "/samples/add_to_lab_shipment_by_barcode",
          data: data,
          dataType: "script",
        });
      },
      showAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
      hideAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
    },
    theme: "bootstrap",
    requestDelay: 500,
  };

  $("#supplier_sample_shipment_barcode_value").focus(function () {
    $(this).select();
  });
  $("#supplier_sample_shipment_barcode_value").easyAutocomplete(options);
  $("#supplier_sample_shipment_barcode_value").focus();

  // ---------------------------------------------------------------------------

  // Fixing markup for clear button
  $("#sample_shipment_barcode_value")
    .closest(".input-group")
    .each(function (i, inputGroup) {
      $(inputGroup).removeClass("input-group");
      var autocomplete = $(inputGroup).find(".easy-autocomplete");
      $(inputGroup).find(".input-group-append").appendTo(autocomplete);
      autocomplete.addClass("input-group");
    });

  $("#supplier_sample_shipment_barcode_value")
    .closest(".input-group")
    .each(function (i, inputGroup) {
      $(inputGroup).removeClass("input-group");
      var autocomplete = $(inputGroup).find(".easy-autocomplete");
      $(inputGroup).find(".input-group-append").appendTo(autocomplete);
      autocomplete.addClass("input-group");
    });

  // ---------------------------------------------------------------------------

  // Clicking sample shipment clear button
  $("#clear_sample_shipment_barcode_button").on("click", function () {
    $("#sample_shipment_barcode_value").val("");
    $("#barcode_match").fadeOut("fast");
    $("#barcode_no_match").fadeOut("fast");
    $("#barcode_already_scanned").fadeOut("fast");
      $("#sample_mismatch").fadeOut("fast");
    $("#sample_shipment_barcode_value").focus();
  });

  // ---------------------------------------------------------------------------

  // catch enter on sample shipment barcode field
  $("#sample_shipment_barcode_value").on("keypress", function (e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      $("#barcode_match").fadeOut("fast");
      $("#barcode_no_match").fadeOut("fast");
      $("#barcode_already_scanned").fadeOut("fast");
      $("#sample_mismatch").fadeOut("fast");
      $("#barcode_spinner").show();

      var data = {
        v: $("#sample_shipment_barcode_value").val(),
        sample_group_id: $("#sample_group_id").data("sample-group-id"),
        shipment_id: $("#shipment_id").data("shipment-id"),
      };
      $.ajax({
        type: "GET",
        url: "/samples/add_to_shipment_by_barcode",
        data: data,
        dataType: "script",
      });
    }
  });
  $("#supplier_sample_shipment_barcode_value").on("keypress", function (e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      $("#barcode_match").fadeOut("fast");
      $("#barcode_no_match").fadeOut("fast");
      $("#barcode_already_scanned").fadeOut("fast");
      $("#sample_mismatch").fadeOut("fast");
      $("#barcode_spinner").show();

      var data = {
        v: $("#supplier_sample_shipment_barcode_value").val(),
        sample_group_id: $("#sample_group_id").data("sample-group-id"),
        shipment_id: $("#shipment_id").data("shipment-id"),
      };
      $.ajax({
        type: "GET",
        url: "/samples/add_to_lab_shipment_by_barcode",
        data: data,
        dataType: "script",
      });
    }
  });

  // ---------------------------------------------------------------------------

  // catch user clearing sample shipment barcode field
  $("#sample_shipment_barcode_value").on("keyup", function (e) {
    if ($(this).val() == "") {
      $("#barcode_spinner").hide();
      $("#barcode_match").fadeOut("fast");
      $("#barcode_no_match").fadeOut("fast");
      $("#barcode_already_scanned").fadeOut("fast");
      $("#sample_mismatch").fadeOut("fast");
    }
  });
  $("#supplier_sample_shipment_barcode_value").on("keyup", function (e) {
    if ($(this).val() == "") {
      $("#barcode_spinner").hide();
      $("#barcode_match").fadeOut("fast");
      $("#barcode_no_match").fadeOut("fast");
      $("#barcode_already_scanned").fadeOut("fast");
      $("#sample_mismatch").fadeOut("fast");
    }
  });

  // ---------------------------------------------------------------------------

  // Check for valid barcode, supplier stocks
  $("#supplier_shipment_barcode_value").on("keyup", function (e) {
    var data = {
      barcode_value: $(this).val(),
      shipment_id: $("#shipment_id").data("shipment-id"),
    };
    $.ajax({
      type: "GET",
      url: "/stocks/check_barcode",
      data: data,
      dataType: "script",
    });
  });

  // ---Barcode Scan Cards End--- //


  // ---------------------------------------------------------------------------

  $("#print_manifest_button").on("click", function (e) {
    window.print();
    e.preventDefault();
  });

  $("#print_timepoint_link").on("click", function (e) {
    window.print();
    e.preventDefault();
  });

  $("#print_blank_form_button").on("click", function (e) {
    window.print();
    e.preventDefault();
  });

  // ---------------------------------------------------------------------------

  // launch tracking number modal
  $("#tracking_number_modal_button").on("click", function (e) {
    e.preventDefault();
    $("#tracking_number_modal").modal();
  });

  // focus tracking number barcode field when modal shown
  $("#tracking_number_modal").on("shown.bs.modal", function (e) {
    $("#tracking_number_value").focus();
  });

  // select all text when tracking number field is focused
  $("#tracking_number_value").focus(function () {
    $(this).select();
  });

  $(".autocomplete-barcode-field").each(initializeTimepointBarcodeField);

  $("form input[type=submit]").click(function () {
    $("input[type=submit]", $(this).parents("form")).removeAttr("clicked");
    $(this).attr("clicked", "true");
  });

  // ---------------------------------------------------------------------------
  // Change the timepoint dates after a subject visit date has been rescheduled using best_in_place
  updateTimepointDateFields();
  initializeTimepickers();

  // ---------------------------------------------------------------------------
  $("#supplier-orders-protocol-id").change(function() {
    $("#supplier-orders-site-id").append('<option value="loading" selected="selected">Loading...</option>');
    $("#supplier-orders-filter-button").prop('disabled', true);

    var protocol_id = $(this).val();
    var supplier_id = $(".supplier_id").data("supplier-id");
    $.post("/suppliers/" + supplier_id + "/fetch_sites_for_protocol", "&protocol_id=" + protocol_id);
  });

  // ---------------------------------------------------------------------------

  $('.purchase_order_modal_button').on('click', function(e) {
    e.preventDefault();

    var supplier_id = $('#supplier_id').data('supplier-id');

    var data = {
      order_id: $(this).data('order-id')
    }

    $.ajax({
      type: "GET",
      url: "/suppliers/" + supplier_id + "/fetch_processing_purchase_orders",
      data: data,
      dataType: "script"
    });
  });

  $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
    if (!$(this).next().hasClass('show')) {
      $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
    }
    var $subMenu = $(this).next('.dropdown-menu');
    $subMenu.toggleClass('show');

    $(this).parents('.dropdown.show').on('hidden.bs.dropdown', function(e) {
      $('.dropdown-submenu .show').removeClass('show');
    });

    return false;
  });

  // ---------------------------------------------------------------------------

  var options = {
    adjustWidth: false,
    getValue: "name",
    url: function (phrase) {
      return ("/sponsors/fetch_active?v=" + phrase);
    },
    listLocation: "sponsors",
    list: {
      maxNumberOfElements: 20,
      match: {
        enabled: true,
      },
      onChooseEvent: function () {},
      showAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
      hideAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
    },
    theme: "bootstrap",
    requestDelay: 500,
  };

  $("#workspace_sponsor_name").easyAutocomplete(options);

  // ---------------------------------------------------------------------------

  $('.sponsor_merge_modal_button').on('click', function(e) {
    e.preventDefault();

    var data = {
      sponsor_id: $(this).data('sponsor-id')
    }

    $.ajax({
      type: "GET",
      url: "/admin/fetch_sponsor_merge_options",
      data: data,
      dataType: "script"
    });
  });

  $('.protocol_merge_modal_button').on('click', function(e) {
    e.preventDefault();

    var sponsor_id = $('#sponsor_id').data('sponsor-id');

    var data = {
      protocol_id: $(this).data('protocol-id')
    }

    $.ajax({
      type: "GET",
      url: "/sponsors/" + sponsor_id + "/fetch_protocol_merge_options",
      data: data,
      dataType: "script"
    });
  });

  // shake login form gently upon incorrect login
  if ($('#login-form .alert.alert-danger.alert-dismissable').text().match(/Invalid Email or password/)) {
    $('#login-form .col').delay(250).effect("shake",{distance: 15, times: 3}, 750); // last number is speed in ms
  }

  // shake login form harder when account is about to be locked
  if ($('#login-form .alert.alert-danger.alert-dismissable').text().match(/You have one more attempt before your account is locked/)) {
    $('#login-form .col').delay(250).effect("shake",{distance: 30, times: 9}, 3000);
  }

  // ---------------------------------------------------------------------------

  $("#donation_link").click(function (e) {
    ahoy.track("Site donation button clicked");
  });

  // ---------------------------------------------------------------------------

  var product_search_options = {
    adjustWidth: false,
    getValue: "name",
    url: function (phrase) {
      return ("/products/fetch_active?v=" + phrase);
    },
    listLocation: "products",
    list: {
      maxNumberOfElements: 20,
      match: {
        enabled: true,
      },
      onChooseEvent: function () {
        var data = {
          v: $("#product_name").val()
        };
        $.ajax({
          type: "GET",
          url: "/products/fetch_product",
          data: data,
          dataType: "script",
        });
      },
      showAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
      hideAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
    },
    theme: "bootstrap",
    requestDelay: 500,
  };

  $("#product_name").easyAutocomplete(product_search_options);

  // catch user clearing product name field
  $("#product_name").on("keyup", function (e) {
    if ($("#product_name").val() == "") {
      $("#product_mpn").val("");
      $("#product_mpn").prop("readonly", false);
    }
  });

  // ---------------------------------------------------------------------------

  var sku_search_options = {
    adjustWidth: false,
    getValue: "name",
    url: function (phrase) {
      return "fetch_active_skus?v=" + phrase;
    },
    listLocation: "skus",
    list: {
      maxNumberOfElements: 20,
      match: {
        enabled: true,
      },
      onChooseEvent: function () {
        var data = {
          v: $("#sku_number").val()
        };
        $.ajax({
          type: "GET",
          url: "fetch_sku",
          data: data,
          dataType: "script",
        });
      },
      showAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
      hideAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
    },
    theme: "bootstrap",
    requestDelay: 500,
  };

  $("#sku_number").easyAutocomplete(sku_search_options);

  // catch user clearing sku number field
  $("#sku_number").on("keyup", function (e) {
    if ($("#sku_number").val() == "") {
      $("#sku_name").val("");
      $("#sku_name").prop("readonly", false);
    }
  });

  // ---------------------------------------------------------------------------

  var vendor_search_options = {
    adjustWidth: false,
    getValue: "name",
    url: function (phrase) {
      return "/vendors/fetch_active?v=" + phrase;
    },
    listLocation: "vendors",
    list: {
      maxNumberOfElements: 20,
      match: {
        enabled: true,
      },
      onChooseEvent: function () {
        var data = {
          v: $("#vendor_name").val()
        };
        $.ajax({
          type: "GET",
          url: "/vendors/fetch_vendor",
          data: data,
          dataType: "script",
        });
      },
      showAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
      hideAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
    },
    theme: "bootstrap",
    requestDelay: 500,
  };

  $("#vendor_name").easyAutocomplete(vendor_search_options);
  $("#procurement_vendor_name").easyAutocomplete(vendor_search_options);

  // catch user clearing vendor name field
  $("#vendor_name").on("keyup", function (e) {
    if ($("#vendor_name").val() == "") {
      $("#vendor_url").val("");
      $("#vendor_url").prop("readonly", false);
    }
  });

  // ---------------------------------------------------------------------------

  $('.receive_materials_modal_button').on('click', function(e) {
    e.preventDefault();

    var manifest_id = $(this).data('manifest-id');

    $.ajax({
      type: "GET",
      url: "/manifests/" + manifest_id + "/receive_material/",
      dataType: "script"
    });
  });


  // ---------------------------------------------------------------------------

  $('.order_depot_selection_modal_button').on('click', function(e) {
    e.preventDefault();

    var supplier_id = $('#supplier_id').data('supplier-id');

    // get checked items from table if there are any
    var selected_orders = [];
    $('#orders-table input:checked').each(function() {
      selected_orders.push($(this).attr('value'));
    });

    var data = {
      order_id: $(this).data('order-id'),
      order_ids: selected_orders
    }

    $.ajax({
      type: "GET",
      url: "/suppliers/" + supplier_id + "/fetch_depots_for_order_dispatch",
      data: data,
      dataType: "script"
    });
  });

  // ---------------------------------------------------------------------------

  $("#workspace_shipping_contact_user_id").change(function () {
    var selected = $(this).val();
    if (selected == "") {
      $("#workspace_override_default_shipping_address_fields").show();
      $("#workspace_override_default_shipping_address_fields").prop("disabled", false);
      $("#workspace_address_attributes_country").prop("disabled", false);
      $('.selectpicker').selectpicker('refresh');
    } else {
      $("#workspace_override_default_shipping_address_fields").prop("disabled", true);
      $("#workspace_address_attributes_country").prop("disabled", true);
      $("#workspace_override_default_shipping_address_fields").hide();
      $('.selectpicker').selectpicker('refresh');
    }
  });

  $("#workspace_pharmacy_shipping_contact_user_id").change(function () {
    var selected = $(this).val();
    if (selected == "") {
      $("#workspace_override_pharmacy_shipping_address_fields").show();
      $("#workspace_override_pharmacy_shipping_address_fields").prop("disabled", false);
      $("#workspace_pharmacy_address_attributes_country").prop("disabled", false);
      $('.selectpicker').selectpicker('refresh');
    } else {
      $("#workspace_override_pharmacy_shipping_address_fields").hide();
      $("#workspace_override_pharmacy_shipping_address_fields").prop("disabled", true);
      $("#workspace_pharmacy_address_attributes_country").prop("disabled", true);
      $('.selectpicker').selectpicker('refresh');
    }
  });


  // ---------------------------------------------------------------------------
  // for subject cohorts reorderable table

  if ($("#subject-cohorts-sortable").length > 0) {
    return $("#subject-cohorts-sortable").sortable({
      axis: "y",
      items: ".item",
      cursor: "move",
      sort: function (e, ui) {
        return ui.item.addClass("active-item-shadow");
      },
      stop: function (e, ui) {
        ui.item.removeClass("active-item-shadow");
        return ui.item.children("td").effect("highlight", {}, 1000);
      },
      update: function (e, ui) {
        var subject_cohort = ui.item.data(
          "subject-cohort-id"
        );
        var position = ui.item.index();
        return $.ajax({
          type: "POST",
          url: "/subject_cohorts/update_row_order",
          dataType: "json",
          data: {
            subject_cohort: {
              subject_cohort_id: subject_cohort,
              row_order_position: position,
            },
          },
        });
      },
    });
  }

  // ---------------------------------------------------------------------------
  // for subject groups reorderable table

  if ($("#subject-groups-sortable").length > 0) {
    return $("#subject-groups-sortable").sortable({
      axis: "y",
      items: ".item",
      cursor: "move",
      sort: function (e, ui) {
        return ui.item.addClass("active-item-shadow");
      },
      stop: function (e, ui) {
        ui.item.removeClass("active-item-shadow");
        return ui.item.children("td").effect("highlight", {}, 1000);
      },
      update: function (e, ui) {
        var subject_group = ui.item.data(
          "subject-group-id"
        );
        var position = ui.item.index();
        return $.ajax({
          type: "POST",
          url: "/subject_groups/update_row_order",
          dataType: "json",
          data: {
            subject_group: {
              subject_group_id: subject_group,
              row_order_position: position,
            },
          },
        });
      },
    });
  }

  // ---------------------------------------------------------------------------

  $('.request_label_modal_button').on('click', function(e) {
    e.preventDefault();

    var shipment_id = $(this).data('shipment-id');
    var redirect_to = $(this).data('redirect-to');

    $.ajax({
      type: "GET",
      url: "/shipments/" + shipment_id + "/request_label/",
      data: {redirect_to: redirect_to},
      dataType: "script"
    });
  });

  // ---------------------------------------------------------------------------

  $('.print_label_modal_button').on('click', function(e) {
    e.preventDefault();

    var shipment_id = $(this).data('shipment-id');
    var redirect_to = $(this).data('redirect-to');

    $.ajax({
      type: "GET",
      url: "/shipments/" + shipment_id + "/print_label/",
      data: {redirect_to: redirect_to},
      dataType: "script"
    });
  });

  // ---------------------------------------------------------------------------

  var manufacturer_search_options = {
    adjustWidth: false,
    getValue: "name",
    url: function (phrase) {
      return "/manufacturers/fetch_active?m=" + phrase;
    },
    listLocation: "manufacturers",
    list: {
      maxNumberOfElements: 20,
      match: {
        enabled: true,
      },
      onChooseEvent: function () {
        var data = {
          m: $("#manufacturer_name").val()
        };
        $.ajax({
          type: "GET",
          url: "/manufacturers/fetch_manufacturer",
          data: data,
          dataType: "script",
        });
      },
      showAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
      hideAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
    },
    theme: "bootstrap",
    requestDelay: 500,
  };

  $("#manufacturer_name").easyAutocomplete(manufacturer_search_options);
  $("#procurement_manufacturer_name").easyAutocomplete(manufacturer_search_options);

  // ---------------------------------------------------------------------------

  var product_manufacturer_search_options = {
    adjustWidth: false,
    getValue: "mpn",
    url: function (phrase) {
      var product = $("#product_id").val();
      var manufacturer = $("#procurement_manufacturer_name").val();
      return "/products/" + product + "/fetch_manufacturers?mpn=" + phrase + "&m=" + manufacturer;
    },
    listLocation: "product_manufacturers",
    list: {
      maxNumberOfElements: 20,
      match: {
        enabled: true,
      },
      showAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
      hideAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
    },
    theme: "bootstrap",
    requestDelay: 500,
  };

  $("#procurement_manufacturer_mpn").easyAutocomplete(product_manufacturer_search_options);

  // ---------------------------------------------------------------------------

  // launch purchase order add to shipment modal
  $('.add-order-to-shipment-button').on('click', function(e) {
    e.preventDefault();

    var order_id = $(this).data('order-id');

    $.ajax({
      type: "GET",
      url: "/orders/" + order_id + "/add_to_shipment/",
      dataType: "script"
    });
  });

  // ---------------------------------------------------------------------------

  var protocol_push_email_field = $("#protocol_push_email");

  var options = {
    placeholder: "Find or invite user",
    adjustWidth: false,
    getValue: "email",
    url: function (phrase) {
      return "fetch_users?email=" + phrase;
    },
    listLocation: "users",
    list: {
      maxNumberOfElements: 10,
      match: {
        enabled: true,
      },
      onChooseEvent: function () {
        NProgress.done();
        NProgress.remove();
        var data = {
          s: $("#protocol_push_email").val(),
        };
        $.ajax({
          type: "GET",
          url: "fetch_user_sites",
          data: data,
          dataType: "script",
        });
      },
      showAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
      hideAnimation: {
        type: "slide",
        time: 150,
        callback: function () {},
      },
    },
    theme: "bootstrap",
    requestDelay: 500,
  };

  protocol_push_email_field.easyAutocomplete(options);

  protocol_push_email_field.on("keyup", function (e) {
    if ($(this).val() == "") {
      $("#protocol_existing_user_fields").hide();
      $("#protocol_site_name").show();
    } else if ($(this).val().length >= 1) {
      var data = {
        s: $("#protocol_push_email").val(),
      };
      $.ajax({
        type: "GET",
        url: "fetch_user_sites",
        data: data,
        dataType: "script",
      });
    }
    if (e.keyCode == 13) {
      e.preventDefault();
      var data = {
        s: $("#protocol_push_email").val(),
      };
      $.ajax({
        type: "GET",
        url: "fetch_user_sites",
        data: data,
        dataType: "script",
      });
    }
  });

  protocol_push_email_field.on("keypress", function (e) {
    if ($(this).val() == "") {
      $("#protocol_existing_user_fields").hide();
      $("#protocol_site_name").show();
    }

    if (e.keyCode == 13) {
      e.preventDefault();
      var data = {
        s: $("#protocol_push_email").val(),
      };
      $.ajax({
        type: "GET",
        url: "fetch_user_sites",
        data: data,
        dataType: "script",
      });
    }
  });

  // ---------------------------------------------------------------------------
  // for subject visit schedules reorderable table

  if ($("#subject-visit-schedules-sortable").length > 0) {
    return $("#subject-visit-schedules-sortable").sortable({
      axis: "y",
      items: ".item",
      cursor: "move",
      sort: function (e, ui) {
        return ui.item.addClass("active-item-shadow");
      },
      stop: function (e, ui) {
        ui.item.removeClass("active-item-shadow");
        return ui.item.children("td").effect("highlight", {}, 1000);
      },
      update: function (e, ui) {
        var subject_visit_schedule = ui.item.data(
          "subject-visit-schedule-id"
        );
        var position = ui.item.index();
        return $.ajax({
          type: "POST",
          url: "/subject_visit_schedules/update_row_order",
          dataType: "json",
          data: {
            subject_visit_schedule: {
              subject_visit_schedule_id: subject_visit_schedule,
              row_order_position: position,
            },
          },
        });
      },
    });
  }

  // fix markup for input group autocomplete on drug parts dash

  $("#drug_recipient_identifier")
    .closest(".input-group")
    .each(function (i, inputGroup) {
      $(inputGroup).removeClass("input-group");
      var autocomplete = $(inputGroup).find(".easy-autocomplete");
      $(inputGroup).find(".input-group-prepend").prependTo(autocomplete);
      autocomplete.addClass("input-group");
    });

  // ---------------------------------------------------------------------------
  //shipment filters
   var update_filters = function(e) {
      const protocol_id = $("#organization-catalog-protocol-id").val()
      const workspace_id = $("#organization-catalog-workspace-id").val()
      const shipment_type = $("#organization-catalog-shipment-type").val()
      const site_id = $("#organization-catalog-site-id").val()

      $.ajax({
        type: "GET",
        url: "fetch_shipment_filters",
        data: {protocol_id: protocol_id, workspace_id: workspace_id, shipment_type: shipment_type, site_id: site_id},
        dataType: "script",
      })
    };

   var reset_filters = function(e) {
      const protocol_id = $("#organization-catalog-protocol-id").val()
      const workspace_id = $("#organization-catalog-workspace-id").val()
      $("#organization-catalog-shipment-type").val("")
      $("#organization-catalog-site-id").val("")
      $.ajax({
        type: "GET",
        url: "fetch_shipment_filters",
        data: {protocol_id: protocol_id, workspace_id: workspace_id},
        dataType: "script",
      })
    };

    $("#filter_block").on("change", "#organization-catalog-protocol-id", update_filters);
    $("#filter_block").on("change", "#organization-catalog-workspace-id", update_filters);
    $("#filter_block").on("change", "#organization-catalog-shipment-type", update_filters);
    $("#filter_block").on("change", "#organization-catalog-site-id", update_filters);

    // ---------------------------------------------------------------------------

    // New Requisition Schema File Format
    if ($("#requisition_file_uploader").val() == "Fisher CSV") {
      $("#requisition_schema_file_attributes").hide();
      
    } else if ($("#requisition_file_uploader").val() == "PDF") {
      $("#requisition_schema_file_attributes").show();
  
    }
  
    $("#requisition_file_uploader").change(function () {
      const selected = $(this).val();
      if (selected == "Fisher CSV") {
        $("#requisition_schema_file_attributes").hide();
       
      } else if (selected == "PDF") {
        $("#requisition_schema_file_attributes").show();
       
      }
    });

    // ---------------------------------------------------------------------------
      // for component substitutes reorderable table

    if ($("#component-substitutes-sortable").length > 0) {
      return $("#component-substitutes-sortable").sortable({
        axis: "y",
        items: ".item",
        cursor: "move",
        sort: function (e, ui) {
          return ui.item.addClass("active-item-shadow");
        },
        stop: function (e, ui) {
          ui.item.removeClass("active-item-shadow");
          return ui.item.children("td").effect("highlight", {}, 1000);
        },
        update: function (e, ui) {
          var component_substitute = ui.item.data(
            "component-substitute-id"
          );
          var position = ui.item.index();
          return $.ajax({
            type: "POST",
            url: "/component_substitutes/update_row_order",
            dataType: "json",
            data: {
              component_substitute: {
                component_substitute_id: component_substitute,
                row_order_position: position,
              },
            },
          });
        },
      });
    }

  
  // ---------------------------------------------------------------------------
  // rerender manifest table row actions button
  $(".journey_bip_field").bind(
    "ajax:success",
    function (event, data, status, xhr) {
      let journey_manifest_id = jQuery.parseJSON(data).journey_manifest_id
      let supplier_id = $(this).data('supplierId')
      let sponsor_id = $(this).data('sponsorId')
      let site_id = $(this).data('siteId')
      
      $.ajax({
        type: "GET",
        url: "/manifests/" + journey_manifest_id + "/redraw_manifest_actions_button/?supplier_id=" + supplier_id + "&sponsor_id=" + sponsor_id + "&site_id=" + site_id,
        dataType: "script"
      });
    }
  );

  // ---------------------------------------------------------------------------


  var handle_confirm_destroy_modal = function(e) {
    e.preventDefault();
    if ($(this).hasClass("disabled")){return}
    if ($("#destroy_modal").is(':visible')){return}
    if ($(this).data('delete-multiple')){
      var checked = $(this)
        .parents("form")
        .find("input[type=checkbox]:checked")
      var collection_ids = []
      checked.each(x => collection_ids.push(checked[x]["value"]))
    };

    var data = {
      resource_id: $(this).data('resource-id'),
      collection_ids: collection_ids
    }
    var url = "/" + $(this).data('controller') + "/confirm_destroy_modal"
    $.ajax({
      type: "GET",
      url: url,
      data: data,
      dataType: "script"
    });
  }
  $('.destroy_modal_trigger_wrapper').on('click', '.confirm_destroy_modal_button', handle_confirm_destroy_modal);

  $("#show-notes").on("click", function(e){
    $("#show-notes").hide()
    $("#study-notes").show()
  })
  $("#hide-notes").on("click", function(e){
    $("#show-notes").show()
    $("#study-notes").hide()
  })
  
  // ------------
  // .side-scroll
    $(".side-scroll").each(function(i, element){
      element.addEventListener('wheel', function(ev){
        if (element.clientWidth < element.scrollWidth){
        ev.preventDefault();
        element.scrollLeft += (ev.deltaY + ev.deltaX);
        }
      });
    })

  ReactRailsUJS.mountComponents();
}); // turbo:load ends here

  // ---------------------------------------------------------------------------
 
  // Drug accountability feature
  $(document).on('click', '.use_drug_modal_button', function(e) {
    e.preventDefault();

    var item_id = $(this).data('item-id');
    var drug_action = $(this).data('drug-action');
    var drug_redirect_path = $(this).data('drug-redirect-path');
    var modal_context = $(this).data('context');

    $.ajax({
      type: "GET",
      url: "/items/" + item_id + "/use_drug/?drug_action=" + drug_action + "&drug_redirect_path=" + drug_redirect_path + "&modal_context=" + modal_context,
      dataType: "script"
    });
  }); 
  
  // Drug accountability feature
    $(document).on('click', '.use_dispense_drug_modal_button', function(e) {
      e.preventDefault();

      var item_id = $(this).data('item-id');
      var drug_action = $(this).data('drug-action');
      var drug_redirect_path = $(this).data('drug-redirect-path');
      var modal_context = $(this).data('context');

      $.ajax({
        type: "GET",
        url: "/items/" + item_id + "/use_dispense_drug/?drug_action=" + drug_action + "&drug_redirect_path=" + drug_redirect_path + "&modal_context=" + modal_context,
        dataType: "script"
      });
    }); 

    $(document).on('click', '.use_reclaim_drug_modal_button', function(e) {
      e.preventDefault();

      var item_id = $(this).data('item-id');
      var drug_action = $(this).data('drug-action');
      var drug_redirect_path = $(this).data('drug-redirect-path');
      var modal_context = $(this).data('context');
      var already_reclaimed = $(this).data('already-reclaimed')

      $.ajax({
        type: "GET",
        url: "/items/" + item_id + "/use_reclaim_drug/?drug_action=" + drug_action + "&drug_redirect_path=" + drug_redirect_path + "&modal_context=" + modal_context + "&already_reclaimed=" + already_reclaimed,
        dataType: "script"
      });
    }); 

    $(document).on('click', '#edit_reclaim_form', function(e) {
      e.preventDefault();
      $("#storage_area_identifier").attr("readonly", false);
      $("#reclaimed_count_field").attr("readonly", false);
      $("#notes").attr("readonly", false);
      $("#edit_reclaim_form").addClass("disabled");
      $("#edit_reclaim_form").attr("aria-disabled", true);
      $("#reclaim_submit").removeClass("disabled")
      $("#reclaim_submit").attr("aria-disabled", false);

    })

  // receive samples modal
  $(document).on('click', '.receive_modal_button', function(e) {
    e.preventDefault();

    var manifest_id = $(this).data('manifest-id');
    var sponsor_id = $(this).data('sponsor-id');
    var supplier_id = $(this).data('supplier-id');

    $.ajax({
      type: "GET",
      url:  "/manifests/receive_samples_modal/?manifest_id=" + manifest_id + "&sponsor_id=" + sponsor_id + "&supplier_id=" + supplier_id,
      dataType: "script"
    });
  });

  // receive multiple samples modal
  $(document).on('click', '.receive_multiple_modal_button', function(e) {
    e.preventDefault();
    var manifest_ids = []
    $('#manifests-table input[type=checkbox]:checked').each(checkbox => {
      if ($('#manifests-table input[type=checkbox]:checked')[checkbox].id != "confirm_extra") {
        manifest_ids.push($('#manifests-table input[type=checkbox]:checked')[checkbox].value)
        }
      });
    var sponsor_id = $(this).data('sponsor-id');
    var supplier_id = $(this).data('supplier-id');
    $.ajax({
      type: "GET",
      url:  "/manifests/receive_samples_modal/?sponsor_id=" + sponsor_id + "&supplier_id=" + supplier_id + "&manifest_ids=" + manifest_ids,
      dataType: "script"
    });
  });

// ---------------------------------------------------------------------------

  // open modal for adding a sample to pending shipment
  $(document).on('click', '.add_to_shipment_link', function(e) {
    e.preventDefault();

    var sample_group_id = $(this).data('sample-group-id');
    var sample_ids = $(this).data('sample-id');
    
    if($("#sample-ids-in-modal").length == 0 || $("#sample-ids-in-modal")[0].value !== sample_ids){
      $.ajax({
        type: "GET",
        url: "/sample_shipments/fetch_pending_shipments/?sample_ids=" + sample_ids,
        dataType: "script"
      });
    } else {
      $("#pending_shipments_modal").modal();
    }
  });

// ------------
    //revert dropdown option
    $(document).on('click', '.revert_samples_link', function(e) {
      e.preventDefault();
      $("#revert_manifest_samples_modal").modal()
      $("#revert_manifest_samples_modal_redirect_to")[0].value = location.href
    });

    //
    $(document).on('click', '.change_sample_lab_link', function(e) {
      e.preventDefault();
      var sample_id = $(this).data('sample-id');
      var target = `#change_sample_lab_modal_${sample_id}`;
      console.log(target)
      $(target).modal();
    });
